import React, { useContext } from "react";
import Context from "../../context/Context";
import { Image as BuilderImage } from "@builder.io/react";

const UpsellImageComponent = props => {
  const { orderImage } = props;
  const {
    currentIndex,
    orderData,
    selectorData: { offers }
  } = useContext(Context);

  let {
    image: imageUrl = `https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d?quality=60&width=300&height=300`
  } = offers[currentIndex]?.checkoutData;

  if (orderImage && orderData?.items?.length) {
    imageUrl = orderData.items[0].product_image;
  }

  return (
    <BuilderImage image={imageUrl} lazy sizes="50vw" className="img-fluid" />
  );
};

export default UpsellImageComponent;
